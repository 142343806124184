import React, { useState, useEffect, useCallback } from "react";
import useFetchData from "../../hooks/useFetchData2";
import styles from "../../sass/CreateProject.module.scss";
import MiniLoader from "../UI/Loader/MiniLoader";

const Step3 = ({ projectName, promptMessages, setProjectId, project_id, step3Complete, setStep3Complete, onNext, user }) => {
  //This is settings the states for various stuff below
  const [inputError, setInputError] = useState(null);
  // const [loadingData, setLoadingData] = useState(false);
  const [pmLoading, setpmLoading] = useState(false);
  //const [user, setUser] = useState(user);
  //const [projectName, setProjectName] = useState(null);
  //const [submitClicked, setSubmitClicked] = useState(false);
  //const [project_id, setProject_id] = useState(null);// this is a crucial piece of this whole thing because I need to pass this to the next child component so that it can be used in the next step. But it has to come from the response of the API call through the useFetchData hook. I am not sure how to do this.
  //I want to make sure certain things are passed from the previous child component to make sure the sequence is followed correctly and or that this child component gets what it needs to complete its step
  //console.log('Step3 User: '+user.uid);
  //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
  console.log('3 ' + user);
  console.log('Step3 PN: ' + JSON.stringify(projectName));
  console.log('Step3 PM: ' + JSON.stringify(promptMessages));

  //console.log(JSON.stringify( {
  //  user_id: user.uid,
  //  project_name: projectName,
  // prompt: promptMessages[0].content, // pass the content property
  // orientation: 'portrait'
  //}),);
  //this is s critical piece of this whole thing because without executing this or receiving the data from the API call, the next step will not work. I need to make sure that the data is received and then I can move on to the next step.
  // const { data, error, loading } = useFetchData(
  //   loadingData ? `project/create` : null,
  //   method="POST",

  //   // `https://video-project-jruo7t4oaq-uc.a.run.app/`, // add comma after baseUrlOverride
  //   `http://localhost:8000/`, // add comma after baseUrlOverride
  //   [loadingData, loading],
  // );
  const { data, loading, error } = useFetchData(
    'project/create',
    'POST',
    {
      user_id: user.uid,
      project_name: projectName,
      prompt: promptMessages[0].content, // pass the content property
      orientation: 'portrait'
    },
    { 'Content-Type': 'application/json' },
    {},
    `https://video-project-jruo7t4oaq-uc.a.run.app/`,
  );

  const handleNext = useCallback(() => {
    setpmLoading(false);
    //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
    setStep3Complete(true); // assuming this function is updating the state correctly
    onNext({
      user,
      project_id,
      step3Complete
    });
  }, [project_id, onNext, setStep3Complete, step3Complete, user]);

  useEffect(() => {
    setpmLoading(true);
    if (data) {
      console.log('Step3: Zeh Deta:' + JSON.stringify(data));
      if (data.statusCode === 200) {
        setProjectId(data.data.project_id);
        setpmLoading(false);
        handleNext(); // Call handleNext here
      }
    } else if (error) {
      console.log('Error: ' + JSON.stringify(error));
      console.log("Step3: Error: " + data.data);
      setpmLoading(false);
      setInputError(error);
    }
  }, [data, error, user, projectName, project_id, promptMessages, setProjectId, onNext, handleNext]);
  return (
    <div>
      <br /><br /><br />
      <h2 className={styles.projectTitle}>Creating Project: {projectName}</h2>

      {error && (
          <div className={styles.errorMessage}>
          Error: {JSON.stringify(error && inputError)}
          </div>
      )}      
 
      <div className={styles.projectTitle}>Initializing Your Creation...</div>
        <MiniLoader loading={loading} />
      </div>
    );
};

export default Step3;
