import classes from "./Input.module.scss"

function Input({ placeholder = "", onInput= () => {} , ...props }) {
  return (
    <input
      type="text"
      className={classes.input}
      placeholder={placeholder}
      onChange={onInput}
      {...props}
    />
  )
}

export default Input
