//import React, { useContext, useState, useEffect } from "react";
import React, { useCallback, useEffect, useState } from "react";
import useFetchData from "../../hooks/useFetchData2";
import styles from "../../sass/HomePage.module.scss";
//import DownloadLink "../DownloadLink/DownloadLink";

const ViewProject = ({ projectData, onBackButtonClick}) => {
  //const [showProjectList, setShowProjectList] = useState(false);
  //const [fetchData, setFetchData] = useState(null);
  const [ dlLoading, setDlLoading ] = useState(false);
  const [downloadAvailable, setDownloadAvailable] = useState(false);
  const project = projectData;
  const { data, loading, error } = useFetchData(
    `project/:${project.project_id}?user_id=${project.user_id}`,
    'GET',
    {},
    { 'Content-Type': 'application/json' },
    {},
    `https://video-project-jruo7t4oaq-uc.a.run.app/`,
  );

  const dlClick = useCallback(() => {
    setDlLoading(loading);
    //{() => downloadAvailable};
    setDlLoading(false);
    console.log('D Click: ' + downloadAvailable);
  }, [loading, downloadAvailable]);

  useEffect(() => {
    setDlLoading(loading);
    if (data) {
      console.log('ViewP: Zeh Deta:' + JSON.stringify(data));
      if (data.statusCode === 200) {
        setDownloadAvailable(data.data.video_url);
        setDlLoading(false);
      } else {
        setDownloadAvailable(data.data.video_url);
        setDlLoading(false);
      }
    } else if(!data) {
      console.log('ViewP: No Data');
      setDlLoading(false);
    } else if (error) {
      console.log('Error: ' + JSON.stringify(error));
      console.log("ViewP: Error: " + data.data);
      setDlLoading(false);
    } else {
      console.log('ViewP: ?');
      setDlLoading(false);
    }
    setDlLoading(false);
  }, [data, loading, dlLoading, error]);

  return (
    <div>
      <div className={styles.projectViewBackButton} onClick={() => onBackButtonClick()}>Go Back</div>
      <div className={styles.projectCameraIconView} onClick={() => dlClick(downloadAvailable)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100" height="105" className={styles.iconFaceTime}>
          <rect className={`${styles.borderColor} ${styles.fillColor}`} x="4" y="7" width="16" height="12" rx="2" ry="2" />
          <path className={`${styles.borderColor} ${styles.fillColor}`} d="M18 12L24 8L24 16z" />
        </svg>
      </div>
      <h1 className={styles.projectlistTitleView}>Idea: {'{ '}{project.project_name}{' }'}</h1>

      <div key={project.project_id} className={styles.projectCard}>
        <div className={styles.projectCardContent}>
          <div className={styles.projectCardBodyView}>
            <div className={styles.projectCardPromptView}>
              <div className={styles.projectCardPromptMagicBody}>
                <div className={styles.projectCardPromptMagic}><br />{'{ MAXMagic }'}<br /><br />
                  <p className={styles.projectCardPromptText}>{project.prompt}</p>
                </div>
              </div>
            </div>
            <div className={styles.projectBottomCardView}>
            <div className={styles.projectCardStatus}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status: {project.project_status}
            <div
              className={`${styles.projectStatus} ${project.project_status === "draft"
                ? styles.draft
                : project.project_status === "completed"
                  ? styles.completed
                  : ""
                }`}
            />
          </div>
          <div className={styles.projectLoadingBarView}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 00 500 20">
              <rect className={styles.projectLoadingBarBackground} x="0" y="0" width="100%" height="100%" rx="5" ry="5" />
              <rect
                className={`${styles.projectLoadingBarProgress} ${project.project_status === "draft"
                  ? styles.projectLoadingBarProgressRed
                  : project.project_status === "completed"
                    ? `${styles.projectLoadingBarProgressGreen}`
                    : ""}`}
                x="0"
                y="0"
                width="50%"
                height="100%"
                rx="3"
                ry="3"
              /></svg>
          </div>
            <div className={styles.clockView}>
              <svg viewBox="0 0 100 100" width="20" height="20">
                <circle cx="50" cy="50" r="48" className={styles.clockFace} />
                <line x1="50" y1="50" x2="50" y2="20" className={styles.hourHand} />
                <line x1="50" y1="50" x2="50" y2="10" className={styles.minuteHand} />
                <circle cx="50" cy="50" r="2" className={styles.clockCenter} />
              </svg>
              <p className={styles.projectCardDateView}><span className={styles.projectCardDateTitle}>Created:</span> {project.created_at}</p>
            </div>
            <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProject;