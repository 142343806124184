import React from "react";
import styles from "./MiniLoader.module.scss";

const MiniLoader = () => {
        return (

            <div className={styles.loaderWrapper}>
                <div className={styles.loader}>
                    {Array(20)
                        .fill(0)
                        .map((_, index) => (
                            <div key={index} className={styles.wave} />
                        ))}
                </div>
            </div>
        );
}
export default MiniLoader;