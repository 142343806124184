import React, { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../hooks/useFetchData2";
import MiniLoader from "../UI/Loader/MiniLoader";
import { FirebaseContext } from "../../contexts/firebase";
import styles from "../../sass/HomePage.module.scss";
import aiBrain from "../../assets/images/global/aiBrainIcon.png";

const ProjectCard = ({ projectsMAP, user, onProjectCardClick, onDeleteButtonClick }) => (
  projectsMAP.map((project) => (
    <div key={project.project_id} className={styles.projectCard}>

      <div classNames={styles.projectCardTop} style={{"width":"100%"}}>
        <div className={styles.deleteButton} onClick={() => onDeleteButtonClick(project)}>Delete</div>
        <div className={styles.projectCameraIcon}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="70" height="60" className={styles.iconFaceTime}>
            <rect className={`${styles.borderColor} ${styles.fillColor}`} x="4" y="7" width="16" height="12" rx="2" ry="2" />
            <path className={`${styles.borderColor} ${styles.fillColor}`} d="M18 12L24 8L24 16z" />
          </svg>
        </div>
      </div>
      <div className={styles.projectCardContent} onClick={() => onProjectCardClick(project)}>
        <div>
          <h3 className={styles.projectCardHeader}>{'{ '}{project.project_name}{' }'}</h3>
            <p className={styles.projectCardPrompt}><span className={styles.projectCardPromptTitle}></span> {project.prompt}</p>
            <div className={styles.projectCardBody}>
            <div className={styles.projectCardStatus}>Status: {project.project_status}
              <div
                className={`${styles.projectStatus} ${project.project_status === "draft"
                  ? styles.draft
                  : project.project_status === "completed"
                    ? styles.completed
                    : ""
                  }`}
              />
            </div>
            <div className={styles.projectLoadingBar}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 20">
                <rect className={styles.projectLoadingBarBackground} x="0" y="0" width="100%" height="100%" rx="5" ry="5" />
                <rect
                  className={`${styles.projectLoadingBarProgress} ${project.project_status === "draft"
                    ? styles.projectLoadingBarProgressRed
                    : project.project_status === "completed"
                      ? `${styles.projectLoadingBarProgressGreen}`
                      : ""}`}
                  x="0"
                  y="0"
                  width="50%"
                  height="100%"
                  rx="3"
                  ry="3"
                /></svg>
            </div>
            <div className={styles.clock}>
              <svg viewBox="0 0 100 100" width="20" height="20">
                <circle cx="50" cy="50" r="48" className={styles.clockFace} />
                <line x1="50" y1="50" x2="50" y2="20" className={styles.hourHand} />
                <line x1="50" y1="50" x2="50" y2="10" className={styles.minuteHand} />
                <circle cx="50" cy="50" r="2" className={styles.clockCenter} />
              </svg>
              <p className={styles.projectCardDate}><span className={styles.projectCardDateTitle}>Created:</span> {project.created_at}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
);

const ErrorMessage = ({ error }) => (
  <div>
    Error: {error.message}
  </div>
);

const ProjectList = ({ onProjectCardClick, handleProjectCardClick, setProjectsData2, user }) => {
   //const { user } = useContext(FirebaseContext);
   //const navigate = useNavigate();
  //const user = 'sbhooley';
  //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
 // const [user, setUser] = useState(user);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [METHODClick, setMETHODClick] = useState('GET');
  const [PATH, setPath] = useState(`/projects?user_id=${user}`);
  const [Project, setProject] = useState(null);

  const onDeleteButtonClick = useCallback((project) => {
    setMETHODClick('DELETE');
    //const P = JSON.stringify(project);
    setPath(`/project/:${project.project_id}?user_id=${user}`);
    setProject(project.project_id);
    console.log('DELETE CLICKED' + JSON.stringify(project));
  }, [setMETHODClick, setPath, user]);

  const { data, error } = useFetchData(
    PATH,
    METHODClick,
    {
      user_id: JSON.stringify(user.uid),
      project_id: Project
    },
    {
      "X-API-Key": process.env.REACT_APP_API_KEY,
      "Access-Control-Allow-Origin": "*",
    },
    {},
    `https://video-project-jruo7t4oaq-uc.a.run.app`,
    [loading, PATH, METHODClick, setMETHODClick, setPath, onDeleteButtonClick, useCallback]
  );

  useEffect(() => {
    if (data?.length > 0) {
      console.log('PL EF Projects:' + data);
      setLoading(false);
    } else if (data) {
      console.log('EF Projects:' + data);
      setProjects(data);
      setLoading(false);
    } else if (data?.statusCode === 404) {
      setLoading(false);
    } else if (error && data?.statusCode === 404) {
      console.log('EF Projects:' + error);
    }
  }, [data, loading, projects, error, setProjects, setLoading, setMETHODClick, setPath, user, onDeleteButtonClick]);

  if (loading) {
    return (
      <div className={styles.projectListLoader}>
        <h3 className={styles.AILoaderTitle}>{'{ AI LOADING DATA... }'}</h3>
        <MiniLoader loading={loading} />
      </div>
    );
  }

  if (projects?.length === 0) {
    console.log(projects);
    setLoading(false);
    return <div>No projects found.</div>;
  } else if (error && data?.statusCode === 404) {
    console.log(error);
    return <ErrorMessage error={error} />;
  } else {
    return (
      <div>
        <h1 className={styles.projectlistTitle}>
          {'{ YOUR CREATIONS }: '} 

        </h1>
        <br />
        <br />
        <div className={styles.projectList}>
          <ProjectCard projectsMAP={projects.data} user={user.uid} onProjectCardClick={onProjectCardClick} onDeleteButtonClick={onDeleteButtonClick} />
        </div>
      </div>
    );
  }
};

export default ProjectList;
