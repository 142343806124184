import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchData = (
  path,
  method,
  body = null,
  headers = {},
  options = {},
  baseUrlOverride = null,
  dependencies = []
) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const url = baseUrlOverride ? `${baseUrlOverride}${path}` : `${process.env.REACT_APP_API_BASE_URL}${path}`;

        const response = await axios({
          url,
          method,
          headers,
          data: body,
          ...options,
        });

        setData(response.data);
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    };

    fetchData();
  }, dependencies);

  return { data, error, loading };
};

export default useFetchData;
