import classes from "./SubHeader.module.scss"

function SubHeader({ children, color = null, size = null, className }) {
  return (
    <h2
      className={`${classes.subheader} ${classes[color]} ${classes[size]} ${className}`}
    >
      {children}
    </h2>
  )
}

export default SubHeader
