import React, { useEffect, useState, useContext } from "react";
import classes from "./Sidebar.module.scss";
import { useNavigate, useLocation } from "react-router-dom";
//import ChatBox from "../MAX/ChatBox";
import { FirebaseContext } from "../../contexts/firebase";

const navLinks = [
  { name: "HomePage", path: "/homepage" },
];

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useContext(FirebaseContext);

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  const handleLogout = async() => {
    try {
      await logout();
      navigate("/logout");
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const handleNavLinkClick = (e) => {
      e.preventDefault();
      navigate(e.target.getAttribute("href"));
      setIsOpen(false);
    };

    const navLinks = document.querySelectorAll(".sidebar__menu a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavLinkClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavLinkClick);
      });
    };
  }, [navigate]);

  return (
    <div className={classes.topbar}>
      <div className={classes.logo}>
        <span className={classes.logoTitle}>MAX</span>
        <span className={classes.logoM}>G</span>
        <span className={classes.logoSubtitle}>en.ai</span>
      </div>
      <div className={classes.hamburger} onClick={handleToggle}>
        <div className={classes.hamburgerLine}></div>
        <div className={classes.hamburgerLine}></div>
        <div className={classes.hamburgerLine}></div>
      </div>
      <ul className={`${classes.menu} ${isOpen ? classes.menuOpen : ""}`}>
        {navLinks.map(({ name, path }, index) => {
          const isActive = location.pathname === path;
          const navLinkClasses = isActive ? classes.active : "";
          return (
            <li key={index} className={classes.menuItem}>
              <a href={path} className={navLinkClasses}>
                {name}
              </a>
            </li>
          );
        })}
        {!isOpen && (
          <li key="logout" className={classes.menuItem}>
            <button className={classes.logOutButton} onClick={handleLogout}>
              LOGOUT
            </button>
          </li>
        )}
      </ul>
      {isOpen && (
        <button className={classes.logoutButton} onClick={handleLogout}>
          Logout
        </button>
      )}
    </div>
  );
}

export default Sidebar;
