import React, { useContext, useEffect } from "react";
import { FirebaseContext } from "../../contexts/firebase";
import Loader from "../../components/UI/Loader/Loader";
import { useNavigate } from "react-router-dom";

function Logout() {
    const { logout, loading } = useContext(FirebaseContext);
    const Navigate = useNavigate();

    useEffect(() => {
        logout();
        Navigate('/login');
    }, [logout, Navigate]);

    if (loading) {
        return <Loader />;
    }
}

export default Logout;
