import classes from "./Button.module.scss"
import React from "react"

function Button({ children, type = "button", disabled, ...props }, ref) {
  return (
    <button
      type={type}
      className={`${classes.button} ${disabled ? classes[""] : ""}`}
      disabled={disabled}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  )
}

export default React.forwardRef(Button)
