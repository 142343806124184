// Step1.js
import React, { useState, useCallback } from "react";
import styles from "../../sass/HomePage.module.scss";

const Step1 = ({ user, projectName, setProjectName, step1Complete, setStep1Complete, onNext }) => {
const [loading, setLoading] = useState(false);

  const handleProjectNameChange = (event) => {
    setLoading(false);
    setProjectName(event.target.value);
  };

  const handleNext = useCallback(() => {
    setLoading(false);
    //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
    setStep1Complete(true); // Only update the state with a boolean value
    onNext({
      user,
      projectName,
      loading,
      step1Complete: true // set step1Complete to true for the onNext function
    });
  }, [loading, setLoading, projectName, setStep1Complete, onNext, user]);
  
  return (
    <div className={styles.createProjectContainer}>
      <h2 className={styles.title}>Let's Name Your Idea...</h2>
      <div className={styles.formContainer}>
        <label htmlFor="projectName" className={styles.label}>
    
        </label>
        <input
          type="text"
          id="projectName"
          value={projectName}
          onChange={handleProjectNameChange}
          className={styles.step2input}
        />
      <button onClick={() => handleNext()} className={styles.button}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Step1;
