import React, { useState, useEffect, useCallback } from "react";
import useFetchData from "../../hooks/useFetchData2";
import styles from "../../sass/CreateProject.module.scss";
import MiniLoader from "../UI/Loader/MiniLoader";
const Step5 = ({ project_id, step5Complete, setStep5Complete, user }) => {
  //const [loading, setLoading] = useState(false);
  //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
  console.log('5 '+user);
  console.log('5 '+project_id);
  const [customizeCaptions, setCustomizeCaptions] = useState(false);
  const structuredCaptionOptions = {
    caption_options: {
      bg_color: "transparent",
      color: "yellow",
      font: "Arial",
      font_size: 18,
      method: "caption",
      position_x: 50,
      position_y: 50,
      stroke_color: "black",
      stroke_width: 1
    }
  };

  const [videoGenerationLoading, setVideoGenerationLoading] = useState(false);
  const [videoGenerationData, setVideoGenerationData] = useState(null);
  const [videoGenerationError, setVideoGenerationError] = useState(null);
  //const [videoGenerationTrigger, setVideoGenerationTrigger] = useState(false);
  //const [fetchDataParams, setFetchDataParams] = useState(null);

  const { data, loading, error } = useFetchData(
    '/video/generate',
    'POST',
    {
      user_id: user.uid,
      project_id: project_id,
      caption_options: structuredCaptionOptions
    },
    {'Content-Type': 'application/json'},
    {},
    `https://video-generator-jruo7t4oaq-uc.a.run.app`,
  );

  useEffect(() => {
    setVideoGenerationLoading(true);
    if (data) {
      if(data.statusCode === 200) {
        console.log('Step5 UF: '+data);
        setVideoGenerationData(data);
        setVideoGenerationLoading(false); 
        setStep5Complete(data);
      }
    } else if (error) {
      console.log('Step5 UF Error: '+error);
      setVideoGenerationError(error);
      setVideoGenerationLoading(false);
    }
    console.log('Step5 UF: '+data);
  }, [videoGenerationLoading, data, step5Complete, setStep5Complete, error]);

  // ... other useEffect hooks

  const handleCustomizeCaptions = (customize) => {
    setCustomizeCaptions(customize);
  };

  const submitCaptionOptions = () => {
    setVideoGenerationLoading(true);
    setVideoGenerationData(null);
    setVideoGenerationError(null);

  }

  const handleNext = useCallback((step5Complete) => {
    setVideoGenerationLoading(false);
    //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
    setStep5Complete(true); // assuming this function is updating the state correctly
    return step5Complete;
  }, [setStep5Complete]);

  return (
    <div className={styles.createProject}>
      <h2>Generating Your Video!</h2>
      {loading || videoGenerationLoading ? (
        <MiniLoader loading={loading} />
      ) : step5Complete ? (
        <button className={styles.button} onClick={() => {
          const videoUrl = videoGenerationData.video_url;
          const link = document.createElement("a");
          link.href = videoUrl;
          link.download = 'download.mp4';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}>
          Download
        </button>
      ) : null}

      {error && (
        <div className={styles.errorMessage}>
          Error: {JSON.stringify(error)}
        </div>
      )}
    </div>
  );
};

export default Step5;
