import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import useFetchData from "../../hooks/useFetchData3";
import styles from "../../sass/HomePage.module.scss";
import MiniLoader from "../UI/Loader/MiniLoader"

const Step2 = ({ projectName, promptMessages, setPromptMessages, setStep2Complete, step2Complete, onNext, user }) => {
  //const [loading, setLoading] = useState(false);
  //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
  console.log('2: '+user);
  console.log('2 PN: '+projectName);
  const [promptLoading, setPromptLoading] = useState(false);
  const [topic, setTopic] = useState("");
  const [inputError, setInputError] = useState(null);
  //const [promptMessages, setPromptMessages] = useState([]);
  //const promptMessagesRef = useRef([]);
  //const [NPM, setNPM] = useState(null);

  const handleGetPrompt = useCallback(() => {
    if (topic.trim() === "") {
      setInputError("Please enter a topic.");
      //setLoading(false);
      setPromptLoading(false);
      return;
    }
    setPromptLoading(true);
    //setLoading(true);
  }, [topic, setPromptLoading]);

  const { data, loading, error } = useFetchData(
    promptLoading ? `?topic=${encodeURIComponent(
      topic + ",and keep it less than 100 words."
    )}&language=English&key=${process.env.REACT_APP_API_KEY}` : null,
    null,
    `GET`,
    {
      headers: {
        "X-API-Key": process.env.REACT_APP_API_KEY,
        "Access-Control-Allow-Origin": "*",
      },
    },  
    null,
    `https://prompt-api-jruo7t4oaq-uc.a.run.app/prompts/6KDuUDB6Dg115CDPLNe2/generate/`,
    [promptLoading, setPromptLoading]
  );

  useEffect(() => {
    //setPromptLoading(true);
    if (data && data.data.choices) {
      setPromptMessages(data.data.choices.map((choice) => ({
        content: choice.message.content
      })));
      //setNPM(newPromptMessages);
      console.log("UF button: " + promptMessages);
      setPromptLoading(false);
      //if(promptLoading === false) {
      setStep2Complete(true);
      //}
      //setLoading(false);
    }
  }, [data, error, projectName, promptMessages, setPromptMessages, setStep2Complete, promptLoading, user]); 

  const handleNext = useCallback(() => {
    //setPromptLoading(false);
    //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
    //if(promptLoading === false) {
      setStep2Complete(true); // Only update the state with a boolean value
    //}
    onNext({
      user,
      projectName,
      promptMessages,
      step2Complete: true // set step2Complete to true for the onNext function
    });
  }, [onNext, projectName, promptMessages, setStep2Complete, user]);

  if (promptLoading || loading) {
    <MiniLoader loading={loading} />
   }

   const buttonContent = useMemo(() => {
    if (promptMessages && promptMessages.length > 0) {
      return (
        <>
          <h3>Rubbing the Lamp...</h3>
          {promptMessages.map((message, index) => {
            <div key={index} className={styles.step2message}>
              {message.content}
            </div>;
            handleNext();
          })}
        </>
      );
    } else {
      return (
        <button className={styles.step2button} onClick={handleGetPrompt}>
          Submit
        </button>
      );
    }
  }, [promptMessages, handleNext, handleGetPrompt]);

  return (
    <div className={styles.createProjectContainer}>
      <h2 className={styles.step2title}>Your Wish is Our Command!</h2>
      <p className={styles.step2description}>Say a statement like "Teach me... Tell me about... Let's talk about... Make me a video about... (Pick anything)" or just ask a question!</p>
      <div className={styles.formContainer}>
        <label htmlFor="topic" className={styles.topicLabel}>
          Topic:
        </label>
        <input
          type="text"
          id="topic"
          value={topic}
          onChange={(event) => {
            setTopic(event.target.value);
            setInputError(null);
          }}
          className={styles.step2input}
        />

{(promptLoading || loading) && <MiniLoader loading={loading} />}

        {inputError && (
          <div className={styles.errorMessage}>{inputError}</div>
        )}
        
        {buttonContent}
        {error && (
          <div className={styles.errorMessage}>
            Error: {error.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step2;
