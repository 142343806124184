import Error404 from "./pages/ErrorPages/Error404";
import React, { useContext, useEffect, useCallback } from "react";
import { Routes, Route, Navigate, BrowserRouter as Router } from "react-router-dom";
import Login from "./pages/Login/Login";
import { FirebaseContext } from "./contexts/firebase";
//import PaymentStatusContext from "./contexts/paymentStatus";
import Loader from "./components/UI/Loader/Loader";
import "./App.css";
import HomePage from "./pages/HomePage";
import Logout from "./pages/Logout/Logout.jsx";

function App() {
  const { user, loading } = useContext(FirebaseContext);
  //const { loading } = useContext(PaymentStatusContext);
  
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={
              user ? (
                  <HomePage />
              ) : (
                <Navigate to="/login" replace />

            )} />
            <Route path="/login" element={<Login />} />
            <Route path="/homepage" element={
              user ? (
                  <HomePage />
              ) : (
                <Navigate to="/login" replace />

            )} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
