import React from "react";
import LayoutWrapper from "../LayoutWrapper/LayoutWrapper";
import classes from "./Loader.module.scss";
import Container from "../Container/Container";
import cartpic from "../../../assets/images/global/cart.jpg"


function Loader() {
    return (
        <LayoutWrapper>
            <Container>
                <div className={classes.customLoadingPage}>
                <div className={classes.loaderText}><span style={{fontWeight: "bold", fontSize: "48pt"}}>MAX</span><span style={{fontSize: "44.5pt"}}>Gen</span><span style={{fontSize: "34pt", fontWeight: "bold"}}>.ai</span></div>
                <h6 className={classes.loaderText1}>AI Calculating Data...</h6>
                    <div className={classes.loader}>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                        <div className={classes.wave}></div>
                    </div>

                </div>
            </Container>
        </LayoutWrapper>
    );
}

export default Loader;
