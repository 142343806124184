import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../contexts/firebase";
import CreateProject from "../components/Projects/CreateProject";
import ProjectList from "../components/Projects/ProjectList";
import ViewProject from "../components/Projects/ViewProject";
import Sidebar from "../components/Sidebar/Sidebar";
import styles from "../sass/HomePage.module.scss";

const HomePage = () => {
  const { user, email, checkSubscriptionStatus } = useContext(FirebaseContext);
  
  console.log("HomePage User: " + JSON.stringify(user));
  //const user = 'sbhooley';
  //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
  const [theUser, setTheUser] = useState(user);
  const [projectData, setProjectData] = useState(null);
  const [showCreateProject, setShowCreateProject] = useState(false); // Change this line
  //const { isPaid3, updatePaymentStatus } = usePaymentStatus();
  const [projectStatus, setProjectStatus] = useState(null);
  //const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showViewProject, setShowViewProject] = useState(false);
  const [showProjectList, setShowProjectList] = useState(true);
  console.log("HomePage User: " + JSON.stringify(user));

  const [viewProjectData, setViewProjectData] = useState(null);
  const [projectsData2, setProjectsData2] = useState(null);
  const [hasSubscriptions, setHasSubscriptions] = useState(false);

  const navigate = useNavigate();

  const handleProjectCardClick = useCallback((projectData) => {
    setViewProjectData(projectData);
    console.log("handleProjectCardClick: " + JSON.stringify(projectData));
    //console.log("viewProjectData: " + JSON.stringify(viewProjectData));
    setShowViewProject(true);
    setShowProjectList(false)
  }, [setViewProjectData]);

  const handleBackButtonClick = () => {
    //console.log("handleBackButtonClick: " + JSON.stringify(projectData));
    //console.log("viewProjectData: " + JSON.stringify(viewProjectData));
    setShowViewProject(false);
    setViewProjectData(null);
    setShowProjectList(true);
  }

  const toggleCreateProject = useCallback(() => {
    //setLoading(false);
    setShowCreateProject(prev => !prev);
  }, []);

  const refreshPaymentStatus = useCallback(async (email, sub) => {
   try {
      const paymentStatus = await checkSubscriptionStatus(email, sub => {
        if (paymentStatus === true) {
          console.log('Has Sub True');
          setHasSubscriptions(true);
        } else {
          console.log('Has Sub False');
          setHasSubscriptions(false);
        }
      });
    } catch(error) {
        console.log(error);
    }
  }, [checkSubscriptionStatus]);

  useEffect(() => {
    if(hasSubscriptions === true) {
      console.log('Has Sub True');
    } else {
      console.log('Has Sub False');
    }
    if (!user) {
     navigate("/logout");
    }
  }, [user, hasSubscriptions, navigate]);
  const isPaid3 = 1;
  return (
    <div className={styles.homePage}>
      <Sidebar className={styles.sidebar} />
      {isPaid3 === 1 ? (
        <>
          <div className={styles.mainContent}>
            <div className={styles.blankColumn}></div>
            <div className={styles.theStuff}>
              <p>
                <span className={styles.cptitle}>Imagination to the MAX...</span>
                <br />
                <br />
                What video content do you want to create? <br />
                <br />
                The world is waiting...
                <br />
                <br />
              </p>
              <button onClick={toggleCreateProject} className={styles.toggleButton}>
                {showCreateProject ? "Hide Project Creation" : "Create Project"}
              </button>
              <br />
              {showCreateProject && <CreateProject />}

            </div>
            <div className={styles.arrowContainer}>
              <div className={styles.arrow}></div>
            </div>
          </div>
          { showProjectList === true ? (
            <ProjectList
            onProjectCardClick={handleProjectCardClick}
            projectData={projectData}
            user={theUser.uid}
            />
          ) : (
            <ViewProject 
            projectData={viewProjectData} 
            setShowProjectList={setShowProjectList} 
            setShowViewProject={setShowViewProject} 
            onBackButtonClick={handleBackButtonClick}
            user={user.uid}
            />
          )}

        </>
      ) : (
        <div className={styles.alertContainer}>
          Your subscription has expired. Please{" "}
          <a href="LINK_TO_PAYMENT_PAGE">renew your subscription</a> to access
          the content.
          <button onClick={refreshPaymentStatus} className={styles.refreshButton}>
            Refresh Payment Status
          </button>
        </div>
      )}
      <div className={styles.footerDisc}>
        <p>
          Disclaimer: Content is not produced by us, our partners, affiliates, employees etc -- and is also not endorsed by vendors whom provide it. Any and all content created and or outputted is not a statement, fact, endorsement, curation, idea etc of us and or our content providers as all content is chosen and generated by the application users. As an entity that has logged into this application and enters input into this application, you hereby agree and or understand you are resposible for anything that is generated by you and or anything that occurs after exporting and or downloading the content from this platform. You hereby undersand and agree this platform and its partners, vendors, affiliates, employees, contractors etc accept aboslutely no liability for any content generated as you are executing your 2nd amendment right under the Constitution of the United States of America. All content is provided freely and or under free to use licensing for the usage through this platform and is not owned by this platform, or its owners, and or managers.
        </p>
      </div>
    </div>
  );
};
export default HomePage;
