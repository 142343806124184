import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { FirebaseProvider } from "./contexts/firebase";
//import PaymentStatusProvider from "./contexts/paymentStatusProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

//const AppWrapper = () => {

 // return (
 //   <PaymentStatusProvider>
 //     <App />
 //   </PaymentStatusProvider>
 // );
///};

root.render(
    <FirebaseProvider>
          <App />
    </FirebaseProvider>
);
