import React, { useState, useEffect, useCallback} from "react";
//import { useLocation } from "react-router";
//import { v4 as uuidv4 } from "uuid";
import useFetchData from "../../hooks/useFetchData2";
import styles from "../../sass/HomePage.module.scss";
import MiniLoader from "../UI/Loader/MiniLoader";

const Step4 = ({ project_id, step4Complete, setStep4Complete, onNext, user }) => {
  //const [speechLoading, setSpeechLoading] = useState(true);
  const [voiceName, setVoiceName] = useState("en-US-Wavenet-A"); // Set initial value
  const [languageCode, setLanguageCode] = useState("en-US"); // Set initial value
  //const [error, setError] = useState(null);
  const [vsLoading, setVSLoading] = useState(false);
  //const location = useLocation();
  //const { project_id, user } = location.state || {};
  //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
  console.log('4 '+user.uid);
  console.log("Step4: "+JSON.stringify(project_id));
  //console.log("Step4: " + user);

  const {data, loading, error } = useFetchData(
    '/text-to-speech/generate',
    'POST',
    {
      user_id: 
      user.uid,
      project_id: 
      project_id,
      tts_settings: {
          language_code: languageCode,
          voice_name: voiceName,
          ssml: true
        }
    },
    {},
    {},
    `https://text-to-speech-jruo7t4oaq-uc.a.run.app`,
  );

  const handleNext = useCallback(() => {
    setVSLoading(false);
    //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';
    setStep4Complete(true); 
    onNext({
      user,
      project_id,
      step4Complete
    });
  }, [project_id, onNext, setStep4Complete, step4Complete, user]);

  useEffect(() => {
    setVSLoading(true);
    if (data) {
      if(data.statusCode === 200) { 
        setVSLoading(false);
        console.log("Step4: Gen Speech UF Data: "+data);
        setStep4Complete(true);
        handleNext();
      } else if (error) {
        setVSLoading(false);
        console.log("Step4: Gen Speech UF Error: "+error);        
      }  else {
        console.log("Step4: Gen Speech UF Else: "+data);
        setVSLoading(false);
      }
    }
    //setVSLoading(false);
    //console.log("Step4: Gen Speech UF: "+data);
  }, [user, project_id,loading, data, error, setStep4Complete, onNext, handleNext]);

  return (
    <div>
      {loading || vsLoading} {
        <>
        <h2>Generating Your Speech!</h2>
        <MiniLoader loading={loading} />
        </>
      }
      {error && (
        <div className={styles.errorMessage}>
          Error: {JSON.stringify(error)}
        </div>
      )}    
      </div>
  );
};

export default Step4;
