import { useState, useEffect, useMemo } from "react";
import axios from "axios";

const useFetchData = (
  path,
  initialData = null,
  method, 
  options = {},
  retryDelay = 5000,
  baseUrlOverride, // Add baseUrlOverride as the 5th argument
  dependencies = [], // Move dependencies to the 6th argument
  baseUrl = process.env.REACT_APP_API_BASE_URL // Default to the value from .env
) => {
// Default to the value from .env
  const MAX_RETRIES = 2;
  const { body, headers } = options;
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  // Use useMemo to create a stable reference for the options object
  const requestOptions = useMemo(
    () => ({
      body,
      headers: {
        "X-API-Key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Referrer-Policy": "unsafe-url",
        //"Referer": "https://7f86-2607-fb91-3f59-9c2c-643a-d7f2-6552-7b9e.ngrok.io",
        //"Referrer": "https://7f86-2607-fb91-3f59-9c2c-643a-d7f2-6552-7b9e.ngrok.io"
      },
    }),
    [body]
  );

  const fetchData = async (customPath, customOptions) => {
    setLoading(true);

    const url = baseUrlOverride ? baseUrlOverride + (customPath || path) : baseUrl + (customPath || path);

    const options = customOptions || requestOptions;

    console.log("Request URL:", url, "Request options:", options);

    try {
      console.log("In the Try --- Request URL:", url, "Request options:", options);

      const response = await axios[method.toLowerCase()](
        url,
        options,
      );

      setData(response.data);
      setLoading(false);
      setError(null);
      setRetryCount(0);
    } catch (error) {
      setLoading(false);
      if (retryCount < MAX_RETRIES) {
        setError(new Error("There was an issue fetching data. Retrying..."));
        setTimeout(() => {
          setRetryCount((prevRetryCount) => prevRetryCount + 1);
        }, retryDelay);
      } else {
        setError(
          new Error(
            "Failed to fetch data after multiple attempts. Please contact support."
          )
        );
      }
    }
  };

  useEffect(() => {
    if (path) {
      console.log("Request URL:", path, "Request options:", options);

      fetchData();
    }
  }, dependencies);

  return {
    data,
    loading,
    error,
    fetchData,
  };
};

export default useFetchData;
