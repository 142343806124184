
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../contexts/firebase";
import classes from "./Login.module.scss";
import Container from "../../components/UI/Container/Container";
import Modal from "../../components/UI/Modal/Modal";
import SubHeader from "../../components/Typography/SubHeader/SubHeader";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";

function Login() {
  const { login, user, setLoading, loading } = useContext(FirebaseContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();
  //console.log('Login: '+user);
  const emailInputHandler = (e) => {
    setEmail(e.target.value);
  };

  const passwordInputHandler = (e) => {
    setPassword(e.target.value);
  };

  const submitFormHandler = async (e) => {
    setLoading(true);
    try {
      const tryLogin = await login(email, password);
      if (tryLogin != null) {
        setLoading(false);
          navigate('/homepage');
      }
    } catch (error) {
      setErrorMessage(error);
      console.log(error);
      //alert(error);
      //alert(errorMessage);
    }
  };

  useEffect(() => {
    if (user) {
      console.log('Login: '+user)
      navigate('/homepage');
    ///navigate('/login');
    }
  }, [user, navigate]);


  //const MemoizedError = React.memo(Error);
  //const MemoizedInput = React.memo(Input);
  //const MemoizedButton = React.memo(Button);

  return (
    <section className={classes.login}>
      <Container className={classes.login__container}>
        <Modal className={classes["login__container__modal"]}>
          <form onSubmit={submitFormHandler} className={classes["login__form"]}>
            <SubHeader color="black" className={classes["login__form__title"]}>
              <div className={classes.loaderText}><span style={{ fontWeight: "bold", fontSize: "48pt" }}>MAX</span><span style={{ fontSize: "40.5pt", fontWeight: "lighter" }}>Gen</span><span style={{ fontSize: "34pt", fontWeight: "bold" }}>.ai</span></div>
            </SubHeader>
            <div className={classes.waveLoader}>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
              <div className={classes.wave}></div>
            </div>
            {errorMessage}
            <Input placeholder="email" onInput={emailInputHandler} />
            <Input placeholder="password" type="password" onInput={passwordInputHandler} />
            <Button className={classes.button} type="submit" onClick={submitFormHandler}>
              Login
            </Button>
          </form>
        </Modal>
      </Container>
    </section>
  );
}

export default Login;

