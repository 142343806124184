import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "../../contexts/firebase";
import styles from "../../sass/HomePage.module.scss";
import Loader from "../../components/UI/Loader/Loader";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const CreateProject = () => {
  const [Step, setStep] = useState(1);
  const [projectName, setProjectName] = useState("");
  const [topic, setTopic] = useState("");
  const [project_id, setProjectId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [promptMessages, setPromptMessages] = useState(null); // new state variable
  const [inputError, setInputError] = useState(null);
  const [projectStatus, setProjectStatus] = useState(null);
  const { user } = useContext(FirebaseContext);
  //const [projectStatus, setProjectStatus] = useState(null);
  const [step1Complete, setStep1Complete] = useState(false);
  const [step2Complete, setStep2Complete] = useState(false);
  const [step3Complete, setStep3Complete] = useState(false);
  const [step4Complete, setStep4Complete] = useState(false);
  const [step5Complete, setStep5Complete] = useState(false);
  const navigate = useNavigate();
  //const user = 'rGwJoBDAqKe2K874IXMQ00rAMMn2';

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  const handleNext = () => {
    // Change this part
    if (step1Complete || step2Complete || step3Complete || step4Complete) {
      setStep(Step + 1);
    } else if (step5Complete) {
      console.log("Step 5 completed");
    } else {
      return;
    }
  };

  const renderStep = () => {
    switch (Step) {
      case 1:
        return (
          <Step1
            user={user}
            projectName={projectName}
            setProjectName={setProjectName}
            setStep1Complete={setStep1Complete}
            step1Complete={step1Complete}
            onNext={() => handleNext(step1Complete)}
          />
        );
      case 2:
        return (
          <Step2
            user = {user}
            projectName={projectName}
            setPromptMessages={setPromptMessages}
            promptMessages={promptMessages}
            setStep2Complete={setStep2Complete}
            step2Complete={step2Complete}
            onNext={() => handleNext(step2Complete)}

          />
        );
      case 3:
        return (
          <Step3
            user={user}
            projectName={projectName}
            promptMessages={promptMessages}
            setProjectId={setProjectId}
            project_id={project_id}
            setStep3Complete={setStep3Complete}
            step3Complete={step3Complete}
            onNext={() => handleNext(step3Complete)}
          />
        );
      case 4:
        return (
          <Step4
            user={user}
            projectName={projectName}
            promptMessages={promptMessages}
            setProjectId={setProjectId}
            project_id={project_id}
            setStep4Complete={setStep4Complete}
            step4Complete={step4Complete}
            onNext={() => handleNext(step4Complete)}
          />
        );
      case 5:
        return (
          <Step5
            user={user}
            project_id={project_id}
            setStep5Complete={setStep5Complete}
            step5Complete={step5Complete}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.createProject}>
      {loading ? <Loader /> : renderStep()}
    </div>
  );
};

export default CreateProject;
