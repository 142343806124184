// FirebaseProvider.js
import { createContext, useEffect, useState, useCallback } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

//import initializeApp from 'firebase/compat/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut  } from 'firebase/auth';
import firebaseConfig from './firebaseConfig';
import WooCommerceAPI from '@woocommerce/woocommerce-rest-api';

import Loader from '../components/UI/Loader/Loader';

export const FirebaseContext = createContext();

const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const firestore = app.firestore();

const WooCommerce = new WooCommerceAPI({
  url: 'https://maxmarket.ai',
  consumerKey: 'ck_2ba5daa0f16bc75e46232bb6284894e9754a2ffa',
  consumerSecret: 'cs_d0f75e3a2e1b21a65db5c8f898945c908e80db98',
  version: 'wc/v3',
});

const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasSubscriptions, setHasSubscriptions] = useState(false);
  const [email, setEmail] = useState(null);
  const [customerID, setCustomerID] = useState(null);

  const checkSubscriptionStatus = async (userEmail, sub, status) => {
    try {
      // Retrieve the WooCommerce customer ID using the email from the Firebase user
      const customerResponse = await WooCommerce.getAsync(`customers?email=${userEmail}`);
      const customers = JSON.parse(customerResponse.toJSON().body);
      if (customers.length === 0) {
        throw new Error('Customer not found in WooCommerce.');
      }
      const customerId = customers[0].id;
      setCustomerID(customerId);

      // Retrieve the customer's subscriptions
      const response = await WooCommerce.getAsync(`subscriptions?customer=${customerId}`);
      const subscriptions = JSON.parse(response.toJSON().body);

      // Check if the user has any active subscriptions
      const activeSubscriptions = subscriptions.filter(
        (subscription) => subscription === {sub},
        //(subscription) => subscription.status === 'active',
        // eslint-disable-next-line no-restricted-globals
        (subscription) =>  subscription.status === {status} ? true : false,
      );
      setHasSubscriptions(activeSubscriptions);
      return (
        hasSubscriptions,
        activeSubscriptions
      );
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const handleLogin = useCallback(async (email, password) => {
    if (!auth) {
        setErrorMessage({ message: "Auth object is not available" });
        return;
    }
    setLoading(true);
    setErrorMessage(null);
    try {
        await signInWithEmailAndPassword(auth, email, password);
        setUser(auth.currentUser)
        setEmail(auth.currentUser.email);
        console.log('FB HL User | Email: '+user+' | '+email);
    } catch (error) {
        setErrorMessage({ message: error.message });
        console.log('FB HL E: '+error);
    } finally {
        setLoading(false);
        console.log('FB HL User | Email: '+user+' | '+email);
        console.log('FB HL E: '+errorMessage);
        return {user, email, errorMessage};
    }
}, [user, errorMessage]);

const handleLogout = useCallback(async () => {
    if (!auth) {
        return null;
    }
    await auth.signOut();
    setLoading(false);
    return 'LoggedOut';
}, []);


useEffect(() => {
  if (!auth) {
      setLoading(false);
      console.log('FB UEE'+user);
      return;
  }

  const unsubscribe = auth.onAuthStateChanged((user) => {
      setLoading(false);
      setUser(user);
      setErrorMessage(null);
      console.log("FB UEE User: "+user);
  });

  return () => unsubscribe();
}, [user, loading]);

  if (loading) {
    return <Loader />;
  }

  

  return (
    <FirebaseContext.Provider value={{ user, email, customerID, login: handleLogin, logout: handleLogout, checkSubscriptionStatus, hasSubscriptions, setLoading, loading }}>
      {children}
    </FirebaseContext.Provider>
  );
};

//async function getRedirectResultAsync() {
//  const result = await getRedirectResult(auth);
//  // Provider of the access token could be Facebook, Github, etc.
//  if (result === null || result.credential === null) {
 //   return null;
 // }
 /// return result;
//}

// Call the function to get the redirect result
//getRedirectResultAsync();

export { FirebaseProvider };
